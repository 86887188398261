<template>
  <div class="propertyMapWrapper block lg:flex map-height">
    <div class="safe-area-background w-full lg:w-0" />
    <div
      v-if="isMinLarge"
      class="sidebar ios-fix"
    >
      <div>
        <div class="properties-sidebar-wrapper">
          <div class="properties-sidebar">
            <PropertyCompactList
              :properties="properties"
              :selected-property="selectedProperty"
              :selected-property-id="selectedPropertyId"
              :diplay-search-results="diplaySearchResults"
              style-classes="desktop-content-container"
              @open="openSelectedProperty"
            >
              <slot />
            </PropertyCompactList>
          </div>
        </div>
      </div>
    </div>
    <div class="relative w-full map-height">
      <div class="absolute top-0 left-1/2 transform -translate-x-1/2 z-10 bg-transparent">
        <div class="flex gap-1 rounded-lg pt-2">
          <StatusFilter
            v-if="featureFlags?.statusFilter"
            v-model:statusFilter="statusFilter"
            :selected-statuses="statusFilter"
            :options="immobilieStatuses"
            ui-mode="map"
          />
          <PersonFilter
            v-if="featureFlags?.personFilter"
            v-model:personFilter="personFilter"
            :selected-persons="personFilter"
            :options="organizationUsers"
            ui-mode="map"
          />
          <ion-button
            v-if="statusFilter.length > 0 || personFilter.length > 0"
            color="light"
            class="m-0 resetButton"
            @click="clearFilters"
          >
            <ion-icon
              slot="icon-only"
              :icon="refreshOutline"
            />
          </ion-button>
        </div>
        <!-- <SurveyDatumFilter
          v-if="featureFlags?.surveyDatumFilter"
          v-model:surveyDatumFilter="surveyDatumFilter"
          :selected-datums="surveyDatumFilter"
          :options="organizationUsers"
          ui-mode="map"
        /> -->
      </div>

      <AppMap
        v-if="isViewEntered"
        identifier="propertyMap"
        :map-settings="currentProject.mapConfig.map"
        style="width: 100%;"
        class="map-height"
        @map:loaded="handleMapLoaded"
        @map:zoom-overview="propertyMap.zoomToOverview(currentProject.mapConfig.map)"
        @point:click="pointClicked"
      >
        <CreatePropertyButton
          v-if="!isMobile"
          class="absolute bottom-3 right-3 z-10"
          @click="openCreateProperty"
        />
      </AppMap>
    </div>
    <ion-modal
      v-if="isMobile"
      ref="modalDetailPage"
      class="modal-style show-always-as-first"
      :is-open="isMobile && showModal && openedPropertyId"
      :backdrop-dismiss="false"
      :can-dismiss="true"
      :initial-breakpoint="initialBreakpointDetailPage"
      :breakpoints="breakpoints"
      :backdrop-breakpoint="1.0"
      :keep-contents-mounted="true"
      @ion-breakpoint-did-change="onBreakpointChangeDetailModal($event)"
    >
      <div class="modal-detail-container">
        <PropertyDetail />
      </div>
    </ion-modal>
    
    <ion-modal
      v-if="isMobile"
      ref="modal"
      class="modal-style show-always-as-second"
      :is-open="isMobile && showModal"
      :backdrop-dismiss="false"
      :can-dismiss="true"
      :initial-breakpoint="initialBreakpoint"
      :breakpoints="breakpoints"
      :backdrop-breakpoint="1.0"
      :keep-contents-mounted="true"
      @ion-breakpoint-did-change="onBreakpointChange($event)"
      @did-present="onModalDidPresent()"
      @did-dismiss="onModalDidDismiss()"
    >
      <CreatePropertyButton
        v-if="isModalInitialized"
        class="floating-button z-10"
        @click="openCreateProperty"
      />
      <PropertyCompactList
        v-if="isModalInitialized"
        :properties="properties"
        :selected-property="selectedProperty"
        :selected-property-id="selectedPropertyId"
        :diplay-search-results="diplaySearchResults"
        style-classes="modal-content-container"
        @open="openSelectedProperty"
      >
        <slot />
      </PropertyCompactList>
    </ion-modal>
  </div>
</template>

<script lang="ts">
import { IonButton, IonContent, IonHeader, IonIcon, IonLabel, IonList, IonModal, IonSpinner } from "@ionic/vue";
import { arrowBack } from "ionicons/icons";
import { ComputedRef, PropType, computed, defineComponent, nextTick, onBeforeUnmount, ref, watch } from "vue";
import { useI18n } from 'vue-i18n';
import { useRouter } from "vue-router";
//Composables
import useScreenSize from "@/composables/useScreenSize";
import { useStore } from "@/composables/useTypedStore";
import { usePropertyMap } from "../../composables/usePropertyMap";
//Models
import Property from "@/models/immobilie.model";
import { getLocalMetaData } from "@/models/local-meta-data";
//Components
import AButton from "@/components/Base/AButton";
import AppMap from '@/components/v2/App/AppMap.vue';
import PropertyCard from "./PropertyCard.vue";
//Other
import _ from 'lodash';
import moment from "moment";
//Swiper
import PropertyDetail from "@/components/properties/PropertyDetail.vue";
import StatusFilter from '@/components/v2/Filters/Property/StatusFilter.vue';
import useUser from "@/composables/useUser";
import Immobilie from "@/models/immobilie.model";
import { ImmobilieStatus, translatedPropertyStatus } from "@/models/immobilie/interfaces/IImmobilie";
import User from "@/models/user";
import { Capacitor } from "@capacitor/core";
import { Keyboard } from '@capacitor/keyboard';
import { chevronForwardOutline, pinOutline, refreshOutline } from "ionicons/icons";
import { MapMouseEvent } from "maplibre-gl";
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import PersonFilter from "../v2/Filters/Property/PersonFilter.vue";
import SurveyDatumFilter from "../v2/Filters/Property/SurveyDatumFilter.vue";
import CreatePropertyButton from "./CreatePropertyButton.vue";
import PropertyCompactList from "./PropertyCompactList.vue";
import PropertyItem from "./PropertyItem.vue";
import PropertyList from "./PropertyList.vue";
export default defineComponent({
  name: "PropertiesMap",
  components: {
    AButton,
    IonButton,
    IonIcon,
    AppMap,
    PropertyCard,
    Swiper,
    SwiperSlide,
    IonSpinner,
    IonModal,
    IonContent,
    PropertyList,
    IonList,
    PropertyItem,
    IonHeader,
    IonLabel,
    PropertyDetail,
    PersonFilter,
    StatusFilter,
    SurveyDatumFilter,
    PropertyCompactList,
    CreatePropertyButton
  },
  props: {
    properties: {
      type: Object as PropType<Property[]>,
      required: true,
    },
    isViewEntered: {
      type: Boolean,
      required: true
    },
    allowLoadMore: {
      type: Boolean,
      default: false
    }
  },
  emits: ["goBack", "loadMore"],
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: 'global' })
    const store = useStore()
    const propertyMap = usePropertyMap(props.properties);
    const selectedPropertyId = ref<number | null>(null);
    const hoveredProp = ref();
    const router = useRouter();
    const propertySlideList = ref([] as any[]);
    const currentProject = computed(() => store.state.user.currentUserProject);
    const propertySwiper = ref();
    const isMapLoaded = ref(false);
    const isSwiperLoaded = ref(false);
    const showLoadingSpinner = ref(false);
    const footerHeight = document.documentElement.style.getPropertyValue('--bottomToolbarHeight') || "50px";
    const modal = ref<InstanceType<typeof IonModal>>();
    const modalDetailPage = ref<InstanceType<typeof IonModal>>();
    const isModalInitialized = ref(false);

    const initialBreakpoint = ref(0.13);
    const breakpoints = [0.13, 0.3, 0.5, 0.7, 1.0];
    const initialBreakpointDetailPage = ref(1);
    const { user } = useUser();
    const featureFlags = computed(() => user.value?.organisation?.featureFlags);

    const selectedProperty = computed(() => {
      return props.properties.find((prop) => prop.id === selectedPropertyId.value);
    });

    const openedPropertyId = computed(() => {
      return store.getters["app/openedPropertyId"];
    });

    const organisationPrefix: ComputedRef<string> = computed(() => {
      const currentUser: User | null = User.query().first();
      return currentUser ? currentUser.organisationPrefix : "";
    });

    const displayCountLabel = computed(() => {
      if (props.properties.length === 0) {
        return t("immobilie.searchResults.noResults", { type: t(`${organisationPrefix.value}toolbar.immobilien`) });
      } else if (props.properties.length === 1) {
        return t("immobilie.searchResults.resultsCount.one", { type: t(`${organisationPrefix.value}createSurvey.object`) });
      } else {
        return t("immobilie.searchResults.resultsCount.other", { count: props.properties.length, type: t(`${organisationPrefix.value}toolbar.immobilien`) });
      }
    });

    const diplaySearchResults = computed(() => {
      return `${t("immobilie.searchResults.title")} ${displayCountLabel.value ? `(${displayCountLabel.value})` : ''}`;
    });
    const organizationUsers = computed(() => {
      return store.state.user.organizationUsers
    });
    const personFilter = ref([]);
    const statusFilter = ref([]);

    function clearFilters() {
        statusFilter.value = [];
        personFilter.value = [];
    }
    // const surveyDatumFilter = ref([]);
    // const surveyDatums = computed(() => {
    //     const uniqueBegehungsdatum = [
    //         ...new Set(
    //             Immobilie.query()
    //             .with('bestandsaufnahmes') // Load the relationship
    //             .get()
    //             .flatMap(record => record.bestandsaufnahmes.map(item => item.begehungsdatum))
    //             .filter(date => date)
    //         ),
    //     ];
    //   return uniqueBegehungsdatum
    // });

    watch([ personFilter, statusFilter ], () => {
        Immobilie.dispatch("setStatusFilter", statusFilter);
        Immobilie.dispatch("setPersonFilter", personFilter);
        // Immobilie.dispatch("setSurveyDatumFilter");
    });

    const showModal = computed(() => router.currentRoute.value.name === "propertiesMap");

    const debouncedFctn = _.debounce(() => {    
      // changesLoading variable caused flickering of object card (show on/off/on/off,...)
      propertySlideList.value = JSON.parse(JSON.stringify(props.properties));
    }, 600)
    const isMobile = computed(() => {
      return store.getters["app/isMobile"];
    });

    watch(() => props.properties, (newVal) => {
        // this code is called when reloading map, but not when entering map from übersicht (-> no slide list)
        debouncedFctn();
        propertyMap.updateProperties(newVal);
        showLoadingSpinner.value = false;
      }
    )

    // this is called when entering map and when reloading map,
    // so it is called twice when reloading (here and in watcher), but executed once due to debounce
    debouncedFctn();

    const { isMinLarge } = useScreenSize();

    const lastFetch = computed(() => {
      const date = moment(getLocalMetaData('lastBackendFetch'));
      return `${date.format('DD.MM.YYYY')} um ${date.format('HH:mm:ss')}`
    });

    const cardClicked = (prop: any) => {
      hoveredProp.value = prop;
      propertyMap.animateToProperty(prop);
    }
    // desktop view
    const openProperty = (prop: any) => {
      const routeData = router.resolve({ path: `/property/${prop.id }` });
      window.open(routeData.href, '_blank');
    }

    const onSlideChange = (e: any) => {
      const prop = propertySlideList.value[e.activeIndex];
      hoveredProp.value = prop;
      propertyMap.animateToProperty(prop);
    }    
    const onSwiperInit = (e: any) => {
      isSwiperLoaded.value = true;
      if(isMapLoaded.value){
        flyToSlide(e.activeIndex);
      }
    }
    const flyToSlide = (goToIndex: any) => {      
      if(hoveredProp.value && propertySwiper.value){     // propertySwiper is undefined here    
        // object is selected -> set slider to this object
        const propIndex = propertySlideList.value.findIndex((val) => {return val.id === hoveredProp.value.id});
        propertySwiper.value?.slideTo(propIndex);

      }else{ // no object selected -> fly to given index
        const prop = propertySlideList.value[goToIndex];
        hoveredProp.value = prop;
        propertyMap.animateToProperty(prop);
      }
    }

    const handleMapLoaded = (map: any) => {
      propertyMap.generateMap(map, currentProject.value.mapConfig.map);
      isMapLoaded.value = true;
      if(isSwiperLoaded.value){
        flyToSlide(0);
      }
    }

    const loadMore = () => {
      if(props.allowLoadMore) {
        showLoadingSpinner.value = true;
        emit("loadMore");
      }
    }

    const setModalBreakpointByMinValue = async (minValue: number) => {
      const current = await modal.value?.$el.getCurrentBreakpoint();
        if(current && current < minValue){
          modal.value?.$el.setCurrentBreakpoint(minValue);
        }
    }

    const scrollPropertyIntoView = (property: Immobilie | void) => {
      if(property) {
        const element = document.getElementById(`prop-item-${property.id}`);
        if(property && element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    }

    const pointClicked = async (e: MapMouseEvent) => {
      const propertyId = propertyMap.getPropertyByPoint(e.point);
      if(propertyId){
        selectedPropertyId.value = propertyId;
        propertyMap.selectProperty(propertyId);
        setModalBreakpointByMinValue(0.5);
        scrollPropertyIntoView(props.properties.find((prop) => prop.id === propertyId));
      } else {
        selectedPropertyId.value = null;
        propertyMap.selectProperty(null);
      }
    }

    const openSelectedProperty = (property: Immobilie | null) => {
      if (property) {
        selectedPropertyId.value = property.id;
        propertyMap.animateToProperty(property);
      }
    }


    const onBreakpointChange = (event: any) => {
      // remember when coming back from other tab
      initialBreakpoint.value = event.detail.breakpoint;
    }

    const onBreakpointChangeDetailModal = (event: any) => {
      if(openedPropertyId.value){
          // remember when coming back from other tab
        initialBreakpointDetailPage.value = event.detail.breakpoint;
      }
    }

    watch(openedPropertyId, (newVal) => {
      if(!newVal){
        //reset to max value when modal is closed
        nextTick(() => {
          // using nextTick to ensure that the modal is already closed
          // there were some weird issues with the modal otherwise
          initialBreakpointDetailPage.value = 1;
        });
      }
    });

    onBeforeUnmount( async () => {
        modal.value?.$el?.dismiss();
        modalDetailPage.value?.$el?.dismiss();
        if (Capacitor.isPluginAvailable("Keyboard")) {
          Keyboard?.removeAllListeners();
        }
        store.commit("app/setOpenedPropertyId", null);
    });

    if (Capacitor.isPluginAvailable("Keyboard")) {
      Keyboard?.addListener('keyboardWillShow', () => {
        document.documentElement.style.setProperty('--bottomToolbarHeight', "0px");
        setModalBreakpointByMinValue(0.3);
      });

      Keyboard?.addListener('keyboardWillHide', () => {
        document.documentElement.style.setProperty('--bottomToolbarHeight', footerHeight);
      });
    }
    const immobilieStatuses = [
        {
          label: translatedPropertyStatus(ImmobilieStatus.ABGESCHLOSSEN, t),
          value: ImmobilieStatus.ABGESCHLOSSEN,
        },
        {
          label: translatedPropertyStatus(ImmobilieStatus.ANGELEGT, t),
          value: ImmobilieStatus.ANGELEGT,
        },
        {
          label: translatedPropertyStatus(ImmobilieStatus.ARCHIVIERT, t),
          value: ImmobilieStatus.ARCHIVIERT,
        },
        {
          label: translatedPropertyStatus(ImmobilieStatus.FREIGEGEBEN, t),
          value: ImmobilieStatus.FREIGEGEBEN,
        },
        {
          label: translatedPropertyStatus(ImmobilieStatus.GEPLANT, t),
          value: ImmobilieStatus.GEPLANT,
        },
        {
          label: translatedPropertyStatus(ImmobilieStatus.IN_DURCHFUEHRUNG, t),
          value: ImmobilieStatus.IN_DURCHFUEHRUNG,
        },
        {
          label: translatedPropertyStatus( "", t),
          value: null,
        },
      ];

    const routeName = computed(() => { return router.currentRoute.value.name; });

    function clearMapFilters() {
        personFilter.value = [];
        statusFilter.value = [];
    }
    watch(routeName, (newVal: any, oldVal: any) => { 
        if (oldVal === "propertiesMap") {
            clearMapFilters();
        }
    });

    const openCreateProperty = () => {
      router.push({ name: 'createProperty' });
    }

    const onModalDidPresent = () => {
      isModalInitialized.value = true;
    }

    const onModalDidDismiss = () => {
      isModalInitialized.value = false;
    }

    return {
      currentProject,
      t,
      isMinLarge,
      lastFetch,
      cardClicked,
      hoveredProp,
      arrowBack,
      openProperty,
      propertySlideList,
      swiperModules: [Navigation],
      handleMapLoaded,
      onSlideChange,
      onSwiperInit,
      propertyMap,
      showLoadingSpinner,
      loadMore,
      modal,
      isMobile,
      pinOutline,
      refreshOutline,
      pointClicked,
      selectedPropertyId,
      selectedProperty,
      diplaySearchResults,
      openedPropertyId,
      openSelectedProperty,
      chevronForwardOutline,
      featureFlags,
      organizationUsers,
      personFilter,
      statusFilter,
      immobilieStatuses,
    //   surveyDatumFilter,
    //   surveyDatums,
      initialBreakpoint,
      breakpoints,
      modalDetailPage,
      initialBreakpointDetailPage,
      onBreakpointChange,
      onBreakpointChangeDetailModal,
      showModal,
      displayCountLabel,
      scrollPropertyIntoView,
      openCreateProperty,
      clearFilters,
      isModalInitialized,
      onModalDidPresent,
      onModalDidDismiss,
    }
  }
});
</script>

<style lang="scss">
.propertyMapWrapper {

  .safe-area-background {
    height: var(--ion-safe-area-top, 0px);
    background: var(--primary, #511A3F);
  }

  .swiperWrapper {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    width: 100%;
    overflow: hidden;

    .swiper {
      .swiper-wrapper {
        margin: 0px auto;

        .swiper-slide {
          ion-card {
            margin: 0 auto !important;
          }
        }
      }

      .swiper-button-next, .swiper-button-prev {
        color: var(--primary);
      }
    }
  }

  .sidebar {
    border-right: 1px solid #9f9f9f;
  }

  .properties-sidebar-wrapper {
    width: 500px;
    background: #f3f5f9;
    overflow: auto;
    padding-right: 24px;
    padding-left: 24px;
    height: calc(100vh - var(--navigation-height, 60px) - var(--ion-safe-area-top, 0px) - var(--ion-safe-area-bottom, 0px));
    overflow-y: scroll;
  }
  .properties-sidebar {
    padding-bottom: 24px;
  }

  h1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .hovered {
    border: 1px solid black;
  }

  @media (max-width: 400px) {
    .property-card {
      width: 300px;
    }
  }

  .ios-fix {
    padding-top: unquote('constant(safe-area-inset-top))') !important;
    padding-top: env(safe-area-inset-top) !important;
  }

  @media (max-width: 1024px) {
    #propertyMap {
      width: 100vw !important;
    }
  }
  .tinycard {
    max-width: 18rem;
  }
  
}

ion-modal.modal-style {
  --safe-area: calc(var(--ion-safe-area-top) + var(--ion-safe-area-bottom));
  --height: calc(95vh - var(--safe-area, 0px) - var(--bottomToolbarHeight) - var(--create-button-height, 5rem)) !important;
  bottom: calc(var(--bottomToolbarHeight) + var(--ion-safe-area-bottom, 0));
  --overflow: visible;

  &.show-always-as-first {
    z-index: 16000 !important;
  }

  &.show-always-as-second {
    z-index: 15000 !important;
  }
}
.modal-detail-container {
    height: 70vh;
    overflow-y: scroll;
}

</style>

<style scoped lang="scss">

:deep(.maplibregl-ctrl-attrib) {
  margin-right: 7rem !important;
}

.map-height {
  height: calc(100vh - var(--navigation-height, 60px) - var(--ion-safe-area-top, 0px) - var(--ion-safe-area-bottom, 0px));
}

.floating-button {
  position: absolute;
  top: calc(var(--create-button-height) * -1 - 0.5rem);
  right: 0.5rem;
}

.resetButton {
    --background: transparent;
    --border-radius: 16px;
    --color: var(--primary);
    --width: auto;
    --border-color: var(--primary);
    --border-style: solid;
    --border-width: 1px;
    font-size: 13px;
    height: 30px;
    --padding-end: 4px;
    --padding-start: 4px;
}

</style>

