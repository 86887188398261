export const environment = {
  production: true,
  message: 'Building for Movinglayers UAT environment',
  SERVER_URL: 'https://survey-uat.movinglayers.eu',
  SERVER_PORT: '443',
  BACKEND_URL: 'https://survey-uat-be.movinglayers.eu',
  BACKEND_PORT: '443',
  SOCKET_PORT: '443',
  OTA_VERSION_URL: 'https://survey-uat-ota.movinglayers.eu/version',
  ENVIRONMENT: "uat",
  BUILD_VERSION: 'v0.1.20-uat.0' //Replaced by CI to something like v0.0.1
}
