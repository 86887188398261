import MapboxDraw, { DrawMode as DefaultDrawMode, MapboxDrawOptions } from "@mapbox/mapbox-gl-draw";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { ExtendDrawBar } from "../../components/v2/Map/extendDrawBar";
import cloneDeep from "lodash.clonedeep";

type DrawMode = DefaultDrawMode & "draw_paint_mode";

type DrawCreateCallback = (feature: any) => void;
type DrawEditCallback = (drawItem: any) => void;

export default function useDraw(mapIdentifier: string) {

    let draw: MapboxDraw;
    let drawOptions: MapboxDrawOptions | undefined;
    const drawControls: any = [];

    // variables are non-reactive to not run into timing problems with vue updating cycles
    let keepInEditMode = false;
    let newDrawingInEditMode: null | string[] = null;

    const controlsMap: any = {
        line_string: "mapbox-gl-draw_line",
        point: "mapbox-gl-draw_point",
        polygon: "mapbox-gl-draw_polygon",
        paint: "draw_paint_class"
    };
    const paintIcon = "paint-brush-icon";

    const drawnEdgesRequired = ref(0);
    const drawnEdgesExisting = ref(0);
    const lastMode = ref<DrawMode>();
    const drawCreateCallback = ref<DrawCreateCallback | null>(null);
    const drawEditCallback = ref<DrawEditCallback | null>(null);
    const beingEdited = ref<null | any[]>(null);

    const { t } = useI18n({ useScope: 'global' });

    const addDrawInstance = (_draw: MapboxDraw, _drawOptions?: MapboxDrawOptions) => {
        draw = _draw;
        drawOptions = _drawOptions;
    };

    const useConfirmAndCancel = computed(() => {
        return mapIdentifier === "surveyMap" && (drawnEdgesRequired.value > 0 || beingEdited.value !== null);
    });

    const confirmDrawButtonDisabled = computed(() => {
        return drawnEdgesExisting.value < drawnEdgesRequired.value;
    });

    const getFreedrawButton = () => {
        return document.querySelector(`#${mapIdentifier} .${controlsMap.paint}`);
    };

    // get last feature in features list that is not null or an empty array
    function getLastFeature() {
        const features = draw?.getAll() ?? [];
        let feature;
        for (let i = features.features.length - 1; i >= 0; i--) {
            const feat = features.features[i];
            // polygon and line need a minimum edges number to be even in the features list
            // don't delete if too little edges because otherwise the last feature is deleted
            // @ts-ignore coordinates not part of type Geometry, but do exist in runtime
            if ((lastMode.value === "draw_polygon" && feat.geometry.type === "Polygon" && feat.geometry?.coordinates?.[0]?.length >= 2) ||
                (lastMode.value === "draw_line" && feat.geometry.type === "LineString" && feat.geometry?.coordinates?.length >= 2) ||
                (feat.geometry.type === "Point" && feat.geometry?.coordinates?.length === 2) ||
                (feat.geometry.type === "MultiLineString" && feat.geometry?.coordinates?.[0]?.length >= 2)
            ) {
                feature = feat;
                break;
            }
        }
        return feature;
    }

    function checkDeleteLastFeatureOnCanvas() {
        if (drawnEdgesRequired.value > 0 && drawnEdgesExisting.value > 0) {
            const feature = getLastFeature();
            if (feature?.id) {
                draw?.delete(feature.id.toString());
            }
        }
    }

    const resetDrawing = () => {
        drawnEdgesExisting.value = 0;
        drawnEdgesRequired.value = 0;
        newDrawingInEditMode = null;
    };

    const cancelDrawing = () => {
        if (beingEdited.value) {
            // @ts-ignore
            beingEdited.value.forEach((edit) => {
                const feature = draw.get(edit.id);
                if (feature) {
                    feature.geometry = edit.geometry;
                    draw.add(feature);
                }
            });
            beingEdited.value = null;
        } else {
            checkDeleteLastFeatureOnCanvas();
            resetDrawing();
        }
        draw?.changeMode("simple_select");
    };

    // set the callback function that is called when a drawing is confirmed
    // workaround to not introduce too many global state variables/functions into this composable
    const setDrawCreateCallback = (callback: DrawCreateCallback) => {
        drawCreateCallback.value = callback;
    };

    const setDrawEditCallback = (callback: DrawEditCallback) => {
        drawEditCallback.value = callback;
    };

    const confirmDrawing = () => {
        if (!confirmDrawButtonDisabled.value) {
            if (beingEdited.value) {
                drawEditCallback.value?.(beingEdited.value[0]);
                beingEdited.value = null;
            } else {
                drawCreateCallback.value?.(getLastFeature());
                resetDrawing();
            }
            draw?.changeMode("simple_select");
        }
    };

    const checkConfirmOrEdit = () => {
        if (drawnEdgesRequired.value > 0 && newDrawingInEditMode !== null) {
            if (keepInEditMode) {
                // necessary because click event is fired after onDrawModeChange
                // in first run do not crea
                keepInEditMode = false;
            } else if (JSON.stringify(newDrawingInEditMode) !== JSON.stringify(draw?.getSelectedIds())) {
                newDrawingInEditMode = null;
                confirmDrawing();
            }
        } else if (beingEdited.value && JSON.stringify(beingEdited.value.map((feature) => feature.id)) !== JSON.stringify(draw?.getSelectedIds())) {
            confirmDrawing();
        }
    };

    const checkIfEditModePossible = () => {
        if (drawnEdgesRequired.value === 0) {
            beingEdited.value = draw?.getSelectedIds()
                // @ts-ignore
                .map((id: string) => ({ id, geometry: cloneDeep(draw.get(id)?.geometry) }))
                .filter((feature) => feature.geometry !== undefined);
            
            if (beingEdited.value.length === 0) {
                beingEdited.value = null;
            }
        }
    };

    // if draw-related return true, else return false
    function onClickDraw() {
        const mode: DrawMode = draw?.getMode() as DrawMode;
        checkConfirmOrEdit();
        checkIfEditModePossible();
        if ((mode === "draw_line_string" ||
            mode === "draw_point" ||
            mode === "draw_polygon" ||
            mode === "draw_paint_mode")) {
            drawnEdgesExisting.value += 1;
            return true;
        }
        if (mode === "direct_select") {
            return true;
        }
        return false;
    }

    const onDrawModeChange = () => {
        const mode = draw?.getMode() as DrawMode;

        // if the mode is changed to another draw mode, delete the last feature on the canvas manually
        // if the same mode button is clicked again, the library automatically deletes the last feature
        if ((mode === "draw_line_string" ||
            mode === "draw_point" ||
            mode === "draw_polygon" ||
            mode === "draw_paint_mode") &&
            lastMode.value !== mode) {
            checkDeleteLastFeatureOnCanvas();
            // reset after deleting the last feature
            resetDrawing();
        } else {
            checkConfirmOrEdit();
        }

        if (drawnEdgesRequired.value > 0 && mode === "simple_select" && newDrawingInEditMode === null) {
            newDrawingInEditMode = draw?.getSelectedIds();
            keepInEditMode = true;
        }

        if (lastMode.value === "draw_paint_mode" && mode !== "draw_paint_mode") {
            getFreedrawButton()?.classList?.remove('active');
        }

        lastMode.value = mode;
        switch (mode) {
            case "draw_line_string":
                drawnEdgesRequired.value = 2;
                break;
            case "draw_point":
                drawnEdgesRequired.value = 1;
                break;
            case "draw_polygon":
                drawnEdgesRequired.value = 3;
                break;
            case "draw_paint_mode":
                getFreedrawButton()?.classList?.add('active');
                drawnEdgesRequired.value = 1;
                break;
            default:
                break;
        }
    };

    const onDrawCreate = () => {
        const mode = draw?.getMode() as DrawMode;
        const feature = getLastFeature();
        if (mode === "draw_point") {
            drawnEdgesExisting.value = 1;
        } else if (mode === "simple_select" && feature?.geometry.type === "MultiLineString") {
            // @ts-ignore
            newDrawingInEditMode = [feature.id?.toString()];
            keepInEditMode = true;
            // @ts-ignore
            draw.changeMode("direct_select", { featureId: newDrawingInEditMode[0] });
        }
    };

    const calculateOffsetAfterDraw = (drawOptions: any) => {
        // if AppMap is refactored to use addControl this could make this function obsolete
        // e.g. mapLibre.value.addControl(new CustomControl(topRightControl.value), Positions.TOP_RIGHT);
        if (drawOptions.controls) {
            const margin = 20;
            const controlHeight = 29;
            // check which controls are enabled
            const offset = Object.values(drawOptions.controls as Record<string, boolean>)
                .reduce((acc: number, val: boolean) => acc + (val ? 1 : 0), 0);
            return offset * controlHeight + margin;
        }
        return null;
    };

    function collectControlElements() {
        if (!drawOptions?.controls) return;
        const mapElement = document.getElementById(mapIdentifier);

        Object.keys(controlsMap).forEach((key) => {
            if (drawOptions?.controls?.[key as DrawMode] === true) {
                drawControls.push(
                    mapElement?.getElementsByClassName(controlsMap[key])[0]
                );
            }
        });
    }

    function setControlVisibility(displayValue: string) {
        drawControls.forEach((control: { style: { display: string; }; }) => {
            control.style.display = displayValue;
        });
    }

    // additional control button "Freihandskizze"
    const drawPaintBtn = {
        on: "click",
        controlName: "paint",
        action: () => {
            if (draw?.getMode() === "draw_paint_mode") {
                draw?.changeMode("simple_select");
                onDrawModeChange();
            } else {
                draw.changeMode("draw_paint_mode");
                onDrawModeChange();
            }
        },
        classes: [paintIcon, controlsMap.paint],
        title: t('appmap.freehand'),
    };
    const getExtendedDrawControl = () => new ExtendDrawBar({
        draw: draw,
        drawOptions: drawOptions,
        buttons: [
            drawPaintBtn,
        ],
    });

    return {
        addDrawInstance,
        onClickDraw,
        onDrawModeChange,
        onDrawCreate,
        useConfirmAndCancel,
        confirmDrawButtonDisabled,
        cancelDrawing,
        confirmDrawing,
        setDrawCreateCallback,
        setDrawEditCallback,
        calculateOffsetAfterDraw,
        getExtendedDrawControl,
        collectControlElements,
        setControlVisibility
    };
}